const zh = {
  nav: {
    cart: "点单",
    orders: "订单",
    clients: "会员",
    settings: "设置",
    dashboard: "分析",
    prods: "商品",
    purchase: "采购",
    purOrders: "采购单",
    suppliers: "供应商",
    users: "员工",
    shops: "门店",
    spCart: "购物车",
    account: "个人中心",
    paidType: "支付方式",
    coin: "货币",
    pnome: "商品名称校准",
    shop: "店铺",
    record: "记录",
  },
  auth: {
    plzLogin: "请登录",
    code: "用户名",
    pwd: "密码",
    login: "登录",
    logout: "登出",
    exit: " 退出程序",
  },
  general: {
    back: "返回",
    search: "搜索",
    cancel: "取消",
    submit: "提交",
    delete: "删除",
    modify: "修改",
    moreDetail: "详情信息",
    addNew: "新增",
    sort: "排序",
    filter: "筛选",
    view: "查看",
    list: "列表",
    confirm: "确认",
    no: "不",
    false: "否",
    true: "是",
    loading: "加载中",
    default: "默认",
    ascending: "升序",
    descending: "降序",
    batch: "批量操作",
    switch: "去",
  },
  cart: {
    totalItems: "货品件数",
    totalPrice: "货品金额",
    order: "下单",
    quantity: "数量",
    price: "单价",
  },
  file: {
    message: {
      selection: "拖拽文件到此处，或者点击此处来选择文件",
      onlyImage: "只可上传图片文件类型",
    },
  },
  formField: {
    at_upd: "更新时间",
    at_crt: "创建时间",
    index: "序号",
    image: "图片",
    code: "编号",
    codeFlag: "基础编号",
    nome: "名称",
    nomeTR: "其他名称",
    Categs: "商品分类",
    is_express: "快捷商品",
    price_sale: "卖价",
    price_regular: "标价",
    price_cost: "成本",
    iva: "税率",
    brand: "品牌",
    shop: "门店",
    unit: "单位",
    weight: "重量",
    pwd: "密码",
    pwdConfirm: "确认密码",
    pwdOrg: "原密码",
    role: "职位",
    city: "城市",
    rate: "汇率",
    symbol: "符号",
    sort: "排序",
    isDefault: "是否默认",
    Coin: "货币",
    is_cash: "现金",
    is_default: "默认",
    Firm: "公司",
    DNS: "域名",
    quantity: "库存",
    totStock: "总成本",
    contact: "联系方式",
    count: "数量",
    Client: "客户",
    Supplier: "供应商",
    crt_at: "创建日期",
    phone: "电话",
    is_simple: "单品",
    addr: "地址",
    zip: "邮编",
    hide_orders: "隐藏订单",
    hide_clients: "隐藏会员",
    allow_Supplier: "供应商",
    Categ_far: "上级分类",
    is_discount: "打折商品",
    company: "公司",
    person: "个人",
    receiverCode: "收发票码",
    emailLaw: "法定邮箱",
    nameLaw: "公司法定名称（参考VISURA）",
    pIva: "公司税号（P.Iva）",
    country: "国家",
    province: "大区",
    address: "地址",
    email: "邮箱",
    fiscalCode: "税号",
    at_handle: "操作时间",
  },
  formGroup: {
    basicInfo: "基础信息",
    cassaAuth: "收银员权限",
    invoice: "发票信息",
    other: "其他",
    basic: "基本信息",
    address: "地址信息",
    contact: "联系信息",
  },
  date: {
    all: "不限",
    today: "今日",
    yesterday: "昨日",
    last3: "近3天",
    last7: "近7天",
    last30: "近30天",
    last90: "近90天",
    Mon: "星期一",
    Tue: "星期二",
    Wed: "星期三",
    Thu: "星期四",
    Fri: "星期五",
    Sat: "星期六",
    Sun: "星期日",
  },
  search: {
    order: "订单",
    prod: "商品",
    purOrder: "采购单",
    code: "单号",
  },
  record: {
    Prod: "商品",
    Order: "订单",
  },
  dashboard: {
    filter: {
      date: "日期",
      field: "模块",
      type: "类型",
    },
    field: {
      general: "总览",
      paidType: "支付方式",
      coin: "货币",
      client: "客户",
      supplier: "供应商",
      product: "商品",
    },
    general: {
      orderPrice: "总销售额",
      purOrderPrice: "总采购额",
      orderCount: "订单总数",
      purOrderCount: "采购单总数",
      orderProducts: "商品销量",
      purOrderProducts: "商品采购量",
    },
    product: {
      salePriceTot: "销售总额",
      salePrice: "销售额",
      saleQuantityTot: "销售总量",
      saleQuantity: "销售量",
      purchasePriceTot: "采购总额",
      purchasePrice: "采购额",
      purchaseQuantityTot: "采购总量",
      purchaseQuantity: "采购量",
    },
    client: {
      countTot: "会员总数",
      countOrderTot: "会员下单数",
      countNew: "新增会员",
      price: "下单金额",
      countOrder: "下单次数",
    },
    supplier: {
      countTot: "供应商总数",
      countOrderTot: "采购总次数",
      countNew: "新增供应商",
      price: "采购金额",
      countOrder: "采购次数",
    },
    paidType: {
      price_coin: "金额",
      rmb: "人民币总额",
      euro: "欧元总额",
      totPay: "支付总额",
      exchange: "换算（€）",
      countPay: "支付次数",
    },
    coin: {
      count: "货币总数",
      countActive: "货币数量",
      totPay: "支付总额",
      countPay: "支付次数",
    },
  },
};

export default zh;
